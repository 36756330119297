#home-header {
	background-image: url('../../img/signs/bg-new-fullhd.svg');
	@include media-breakpoint-down(lg) {
		@include orientation(portrait) {
			background-image: url('../../img/signs/bg-new-square.svg');
		}
	}
}

#home-company {
	position: relative;
	background-image: url('../../img/home/company.jpg');

	@include media-breakpoint-down(lg) {
		@include orientation(portrait) {
			background-image: url('../../img/home/company-s.jpg');
		}
	}

	.gradient {
		@include gradient-y(rgba($body-bg, 1), rgba($body-bg, 0));
	}
}

.home-why {
	position: relative;
	background-color: $light-blue;
	padding: 40px 0;

	@include media-breakpoint-up(md) {
		padding: 60px 0;
	}
	@include media-breakpoint-up(xl) {
		padding: 80px 0;
	}

	.carousel-item {
		position: relative;

		&:before {
			content: '';
			padding-top: 50%;
			display: block;

			@include media-breakpoint-down(md) {
				@include orientation(portrait) {
					padding-top: 100%;
				}
			}
		}

		.carousel-item-inner {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			padding: 0 20%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.signs {
		position: absolute;
	}
	.sign1 {
		transform: translate3d(20%, -50%, 0);
		top: 0;
		left: 0;
		width: 10%;

		@include media-breakpoint-down(md) {
			transform: translate3d(-30%, -50%, 0);
			width: 15%;
		}
	}
	.sign2 {
		transform: translate3d(-10%, -30%, 0);
		top: 0;
		right: 0;
		width: 20%;

		@include media-breakpoint-down(md) {
			transform: translate3d(20%, -50%, 0);
			width: 25%;
		}
	}
	.sign3 {
		transform: translate3d(0, 12%, 0);
		bottom: 0;
		left: 0;
		width: 20%;
		@include media-breakpoint-down(md) {
			width: 25%;
		}
	}
	.sign4 {
		transform: translate3d(5%, 5%, 0);
		bottom: 0;
		right: 0;
		width: 20%;
		@include media-breakpoint-down(md) {
			width: 25%;
		}
	}
}

.solution-item {
	position: relative;

	.solution-bg {
		position: absolute;
		top: 0;
		bottom: 0;

		&.composer {
			left: 0;
			right: 20%;
			background: $my-orange;
		}
		&.cloud {
			left: 20%;
			right: 0%;
			background: $my-violet;
		}
	}

	.solution-image-box {
		margin: 20% 0;
		position: relative;

		&.composer {
			margin-left: 10%;
		}
		&.cloud {
			margin-right: 10%;

			.cloud-mobile {
				position: absolute;
				z-index: 2;
				top: 0;
				right: 0;
				transform: scale(0.6) translate3d(20%, -40%, 0);
				transform-origin: top right;
				width: 75%;

				@include media-breakpoint-up(xl) {
					transform: scale(0.7) translate3d(20%, -40%, 0);
				}
			}
			.browser {
				position: relative;
				z-index: 1;
			}
		}
	}

	.video-wrapper {
		max-width: 100%;
		overflow: hidden;
		position: relative;
		background-image: url('../../img/solutions/composer.jpg');

		&:before {
			content: '';
			padding-top: 75%;
			display: block;
		}

		video {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.partnership-box {
	.container {
		position: relative;

		.sign {
			position: absolute;
			transform: translate3d(-50%, -20%, 0) rotate(70deg);
			top: 0;
			right: 0;
			width: 20%;
			max-width: 120px;
		}
	}
	.partnership-logos {
		display: flex;
		flex-wrap: nowrap;
		align-items: flex-end;
		justify-content: space-between;

		@include media-breakpoint-down(md) {
			@include orientation(portrait) {
				flex-direction: column;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				gap: 30px;
			}
		}

		div {
			text-align: center;
			@include media-breakpoint-down(md) {
				@include orientation(portrait) {
					width: 100%;
				}
			}
		}

		.partnership-img {
			max-width: 60%;
			@include media-breakpoint-down(md) {
				@include orientation(portrait) {
					max-width: 60%;
					max-height: 60px;
				}
			}

			@include media-breakpoint-up(xl) {
				max-width: 50%;
			}
		}
	}
}

.home-services-icon {
	font-size: 0.6em;
	position: relative;
	top: -3px;
}
