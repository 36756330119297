// GENERAL
.bg-cover {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

@mixin orientation($direction) {
	$orientation-landscape: '(orientation:landscape)';
	$orientation-portrait: '(orientation:portrait)';

	@if $direction == landscape {
		@media #{$orientation-landscape} {
			@content;
		}
	}
	@if $direction == portrait {
		@media #{$orientation-portrait} {
			@content;
		}
	}
}

// TEXT
mark {
	position: relative;
	background: transparent;
	padding: 0;
	color: $body-color;
	z-index: 1;

	&:after {
		content: '';
		position: absolute;
		left: -3%;
		width: 100%;
		bottom: 10%;
		height: 25%;
		background: $secondary;
		z-index: -1;
	}
}

.bordered-title {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: -0.75rem;
		height: 1em;
		border-right: 3px dashed $primary;
	}
}

// FULLPAGE
.fullpage {
	height: 100vh;

	.logo {
		width: 80px;
		@include media-breakpoint-up(md) {
			width: 120px;
		}
	}
}

section,
.section-content {
	&.dark {
		background-color: $dark-blue;
	}
	&.light {
		background-color: $light-blue;
	}
	&.white {
		background-color: $white;
	}
	&.drop-front {
		z-index: 2;
	}
	&.drop-back {
		z-index: 1;

		top: -100px;
		padding-top: 60px;
		margin-bottom: -100px;

		@include media-breakpoint-up(md) {
			top: -140px;
			padding-top: 80px;
			margin-bottom: -140px;
		}
		@include media-breakpoint-up(xl) {
			top: -180px;
			padding-top: 100px;
			margin-bottom: -180px;
		}
	}
}

// HEADERS
.section-full {
	height: 100vh;

	a {
		text-transform: uppercase;
	}

	.section-inner {
		height: 100vh;
		width: 90%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(md) {
			width: 75%;
		}

		p.small {
			font-size: $font-size-base * 1.1;
			@include media-breakpoint-down(sm) {
				@include orientation(portrait) {
					font-size: $font-size-base * 0.8;
				}
			}
		}
		p.big {
			@include media-breakpoint-up(md) {
				font-size: $font-size-base * 1.5;
			}
		}
	}
}

.section-header {
	position: relative;

	a {
		text-transform: uppercase;
	}

	.section-inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&:before {
		content: '';
		display: block;
		padding-top: 56.25%;

		@include media-breakpoint-down(md) {
			@include orientation(portrait) {
				padding-top: 125%;
			}
		}
		@include media-breakpoint-down(lg) {
			@include orientation(portrait) {
				padding-top: 100%;
			}
		}
	}
}

// CONTENTS
.section-content {
	position: relative;

	a {
		text-transform: uppercase;
	}

	.section-inner {
		padding: 40px 25px;
		margin: 0 auto;

		&.no-bottom {
			padding-bottom: 0;
		}

		@include media-breakpoint-up(md) {
			padding: 60px 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 80px 30px;
		}

		&.sm {
			max-width: 720px;
			@include media-breakpoint-up(xl) {
				max-width: 900px;
			}
		}
	}
}

.content-image {
	position: relative;

	&:before {
		content: '';
		display: block;
		padding-top: 56.25%;

		@include media-breakpoint-down(lg) {
			@include orientation(portrait) {
				padding-top: 100%;
			}
		}
	}

	&.square {
		&:before {
			content: '';
			display: block;
			padding-top: 100%;
			@include media-breakpoint-down(lg) {
				padding-top: 56.25%;
			}
			@include media-breakpoint-down(md) {
				@include orientation(portrait) {
					padding-top: 100%;
				}
			}
		}
	}

	.content-image-text {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.list-unstyled {
	li > svg {
		margin-right: 0.5rem;
	}
}

.gradient {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	bottom: 50%;
	z-index: 1;
}

.gradient-bottom {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	bottom: 0;
	z-index: 1;
}

ul {
	li.check-list {
		display: flex;
		margin-bottom: $spacer * 0.5;
		&:last-child {
			margin-bottom: 0;
		}

		svg {
			margin-top: 0.3rem;
		}
	}
}
