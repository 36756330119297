#company-header {
	background-image: url('../../img/signs/bg-company-fullhd.svg');
	@include media-breakpoint-down(lg) {
		@include orientation(portrait) {
			background-image: url('../../img/signs/bg-company-square.svg');
		}
	}
}

.company-steps {
	overflow: hidden;
	position: relative;
	left: 4%;

	@include media-breakpoint-down(sm) {
		@include orientation(portrait) {
			left: 0;
		}
	}

	.step-item {
		position: relative;
		float: left;
		width: 25%;

		.step-item-inner {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			gap: 1rem;

			@include media-breakpoint-down(md) {
				@include orientation(landscape) {
					gap: 0.5rem;
				}
			}

			@include media-breakpoint-down(sm) {
				@include orientation(portrait) {
					gap: 1rem;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			@include orientation(portrait) {
				float: none;
				width: 80%;
				margin: 0 auto;
			}
		}

		&:before {
			content: '';
			padding-top: 100%;
			display: block;
		}
	}

	.duo-primary-swap {
		font-size: $font-size-base * 1.5;
		@include media-breakpoint-down(sm) {
			@include orientation(portrait) {
				font-size: $font-size-base * 3;
			}
		}
		@include media-breakpoint-up(lg) {
			font-size: $font-size-base * 2.5;
		}
		@include media-breakpoint-up(xl) {
			font-size: $font-size-base * 3;
		}
		@include media-breakpoint-up(xxl) {
			font-size: $font-size-base * 4;
		}
	}

	h3 {
		text-transform: uppercase;
		font-size: $font-size-base;
		@include media-breakpoint-down(md) {
			@include orientation(landscape) {
				font-size: $font-size-base * 0.8;
			}
		}
		@include media-breakpoint-down(sm) {
			@include orientation(portrait) {
				font-size: $font-size-base * 1.2;
			}
		}
		@include media-breakpoint-up(xxl) {
			font-size: $font-size-base * 1.5;
		}
	}

	#company-step-1 {
		background-image: url('../../img/company/step1.svg');
		@include media-breakpoint-down(sm) {
			@include orientation(portrait) {
				background-image: url('../../img/company/step1-p.svg');
			}
		}
	}
	#company-step-2 {
		background-image: url('../../img/company/step2.svg');
		transform: translate3d(-12%, 0, 0);
		@include media-breakpoint-down(sm) {
			@include orientation(portrait) {
				background-image: url('../../img/company/step2-p.svg');
				transform: translate3d(0, -12%, 0);
			}
		}
	}
	#company-step-3 {
		background-image: url('../../img/company/step3.svg');
		transform: translate3d(-22%, 0, 0);
		@include media-breakpoint-down(sm) {
			@include orientation(portrait) {
				background-image: url('../../img/company/step3-p.svg');
				transform: translate3d(0, -22%, 0);
			}
		}
	}
	#company-step-4 {
		background-image: url('../../img/company/step4.svg');
		transform: translate3d(-34%, 0, 0);
		@include media-breakpoint-down(sm) {
			@include orientation(portrait) {
				background-image: url('../../img/company/step4-p.svg');
				transform: translate3d(0, -34%, 0);
			}
		}
	}
}

#company-image1 {
	background-image: url('../../img/home/company.jpg');

	@include media-breakpoint-down(lg) {
		@include orientation(portrait) {
			background-image: url('../../img/home/company-s.jpg');
		}
	}

	.gradient {
		@include gradient-y(rgba($dark-blue, 1), rgba($dark-blue, 0));
	}

	.section-inner {
		position: relative;
		z-index: 2;
		p {
			@include media-breakpoint-up(md) {
				margin-top: 10%;
				width: 70%;
			}
		}
	}
}

.why-box {
	.container {
		position: relative;

		.sign {
			position: absolute;
			transform: translate3d(-50%, -30%, 0);
			top: 0;
			right: 0;
			width: 20%;
			max-width: 120px;
		}
	}
}

#company-image2 {
	background-image: url('../../img/company/why1.jpg');
}

#company-image3 {
	background-image: url('../../img/company/why2.jpg');
}

#company-image4 {
	background-image: url('../../img/company/why3.jpg');
}
