@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Nunito+Sans:wght@900&display=swap');

$font-family-sans-serif: 'Mulish', sans-serif;
$headings-font-family: 'Nunito Sans', sans-serif;
$input-btn-font-family: 'Nunito Sans', sans-serif;

$body-bg: #000016;
$body-color: #ffffff;

$primary: #28e6d4;
$secondary: #296bff;
$link-color: #28e6d4;
$dark-blue: #181830;
$dark-blue2: #343a56;
$light-blue: #00a1bc;

$my-orange: #ff9c00;
$my-violet: #7b4dff;

$form-feedback-valid-color: $primary;

$input-border-color: $body-color;
$form-check-input-border: 1px solid $body-color;

@import '~bootstrap/scss/bootstrap';

.alert-primary {
	background-color: $primary;

	.duo-primary {
		--fa-primary-color: #ffffff;
		--fa-primary-opacity: 0.6;
		--fa-secondary-color: #ffffff;
		--fa-secondary-opacity: 0.8;
	}
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
	color: $body-color;
}
