@import 'theme';
@import 'header';
@import 'utils';
@import 'icon';
@import 'devices';
@import 'pages/home';
@import 'pages/company';
@import 'pages/services';
@import 'pages/solutions';

html {
	margin: 0;
	padding: 0;

	body.menuOpen {
		padding-right: 8px;
	}
}
body::-webkit-scrollbar {
	width: 8px;
}
body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
body::-webkit-scrollbar-thumb {
	background-color: $primary;
	outline: none;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	text-decoration: none;
	text-transform: none;
	color: inherit;
}

.content {
	white-space: pre-line;
}

.logo {
	background-image: url('../img/logo-payoff.svg');

	&:before {
		content: '';
		padding-top: 125%;
		display: block;
	}
}

.privacy-page {
	padding-top: 200px;
}

// FOOTER
#footer {
	padding: 0 25px;
	background-image: url('../img/signs/bg-footer-fullhd.svg');
	@include media-breakpoint-up(md) {
		padding: 0 30px;
	}
	@include media-breakpoint-down(lg) {
		@include orientation(portrait) {
			background-image: url('../img/signs/bg-footer-square.svg');
		}
	}

	.footer-contacts {
		max-width: 600px;
		margin: 0 auto;
		padding: 80px 0;

		@include media-breakpoint-up(md) {
			padding: 120px 0;
		}

		.contacts-form {
			margin-top: 20px;
		}
	}

	.footer-copy {
		font-size: 0.6rem;
		@include media-breakpoint-up(md) {
			font-size: 0.7rem;
		}
		@include media-breakpoint-up(lg) {
			font-size: 0.8rem;
		}
	}

	textarea {
		resize: none;
	}
}
