header {
	.topbar {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 64px;
		padding: 10px 0;
		z-index: 10;

		.topbar-langs {
			opacity: 0;

			a {
				color: $body-color;
				padding: 10px;
				text-decoration: none;
			}
		}

		.topbar-contacts {
			@include media-breakpoint-down(md) {
				visibility: hidden;
			}
			a {
				color: $body-color;
				text-decoration: none;
			}
		}

		.logo {
			width: 35px;
		}

		.hamburger-button {
			background: transparent;
			color: $body-color;
			border-radius: 50%;
			border: none;
			padding: 0;
			outline: inherit;
			height: 40px;
			width: 40px;
			overflow: hidden;

			.hamburger-react {
				top: -4px;
				left: -4px;
			}

			&.close {
				transition: background-color 0.5s;
				background-color: $primary;
			}
		}
	}

	.topbar-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 64px;
		z-index: 8;
		background: $body-bg;
	}

	.menu {
		display: none;
		z-index: 9;
		top: 0;
		left: 0;
		right: 0;
		position: fixed;
		width: 100vw;
		height: 100%;

		.menu-secondary-background-color {
			background: $primary;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: fixed;
			height: 100%;
			width: 100%;
			z-index: -1;
		}

		.menu-layer {
			position: relative;
			background: $secondary;
			height: 100%;
			overflow: hidden;

			.wrapper {
				position: relative;
				margin-top: 140px;
				height: calc(100vh - 140px);
				overflow-y: auto;

				@include media-breakpoint-up(md) {
					align-items: center;
					display: flex;
				}

				.row {
					min-height: calc(100vh - 140px);
					display: flex;
					justify-content: space-between;

					@include media-breakpoint-up(md) {
						min-height: auto;
						align-items: normal;
					}
				}

				.menu-links {
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: relative;
					margin-bottom: 60px;

					@include media-breakpoint-up(md) {
						margin-bottom: 0;
					}

					nav {
						display: block;
						width: 100%;

						ul {
							margin: 0;
							padding: 0;

							li {
								list-style: none;
								font-family: 'Nunito Sans', sans-serif;
								font-size: 2rem;
								font-weight: 600;
								cursor: pointer;
								width: 100%;
								overflow: hidden;
								position: relative;
								height: 3rem;
								line-height: 3rem;

								@include media-breakpoint-up(xxl) {
									font-size: 3rem;
									height: 4rem;
									line-height: 4rem;
								}

								&.menu-sub-item {
									font-size: 1.2rem;
									line-height: 2.4rem;
									height: 2.4rem;
									padding-left: 1rem;

									@include media-breakpoint-up(xxl) {
										font-size: 1.5rem;
										height: 3rem;
										line-height: 3rem;
									}
								}

								a {
									position: absolute;
									color: #ffffff;
									text-decoration: none;

									&:hover {
										color: $black;
									}
								}
							}
						}
					}
				}

				.menu-info {
					display: flex;
					align-items: flex-end;
					padding-bottom: 10px;

					h3 {
						font-size: 1rem;
						margin-bottom: 5px;

						@include media-breakpoint-up(xl) {
							font-size: 1.2rem;
						}

						@include media-breakpoint-up(xxl) {
							font-size: 1.5rem;
						}
					}
					p {
						font-size: 0.8em;
						margin-bottom: 5px;

						@include media-breakpoint-up(xl) {
							font-size: 1rem;
						}

						@include media-breakpoint-up(xxl) {
							font-size: 1.1rem;
						}
					}
				}
			}
		}
	}
}
