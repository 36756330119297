#services-header {
	background-image: url('../../img/signs/bg-services-fullhd.svg');
	@include media-breakpoint-down(lg) {
		@include orientation(portrait) {
			background-image: url('../../img/signs/bg-services-square.svg');
		}
	}
}

.service-text-box {
	background-color: $dark-blue; //$body-bg;
	height: 100%;
	padding: $spacer * 1.5;

	@include media-breakpoint-up(md) {
		padding: $spacer * 3;
	}

	strong {
		color: $primary;
	}

	p:last-child {
		margin-bottom: 0;
		font-size: $font-size-base * 1.1;
		@include media-breakpoint-up(xl) {
			font-size: $font-size-base * 1.2;
		}
		@include media-breakpoint-up(xxl) {
			font-size: $font-size-base * 1.4;
		}
	}
}

.service-image-highlight {
	.gradient {
		//@include gradient-y(rgba($dark-blue, 1), rgba($dark-blue, 0));
		@include gradient-y(rgba($body-bg, 1), rgba($body-bg, 0));
	}
	.gradient-bottom {
		@include gradient-y(rgba($dark-blue, 0), rgba($dark-blue, 1));
	}

	.section-inner {
		position: relative;
		z-index: 2;

		strong {
			color: $primary;
		}

		p {
			@include media-breakpoint-up(md) {
				margin-top: 10%;
				width: 70%;
			}
		}
	}
}

$services: ('web', 'dt', 'bpr', 'ai', '3d', 'i4', 'blockchain', 'ar');

@each $service in $services {
	#image1-#{$service} {
		background-image: url('../../img/services/#{$service}.jpg');
		@include media-breakpoint-down(lg) {
			@include orientation(portrait) {
				background-image: url('../../img/services/#{$service}-s.jpg');
			}
		}
		@include media-breakpoint-down(md) {
			@include orientation(portrait) {
				background-image: url('../../img/services/#{$service}.jpg');
			}
		}
	}

	#image2-#{$service} {
		background-image: url('../../img/services/#{$service}-2.jpg');
		@include media-breakpoint-down(lg) {
			@include orientation(portrait) {
				background-image: url('../../img/services/#{$service}-2-s.jpg');
			}
		}
	}
}

.features-col {
	@include media-breakpoint-up(lg) {
		padding: 0 10%;
	}
}

.services-btn-box {
	.section-inner {
		padding-top: 0;
		padding-bottom: 0;
	}

	.services-btn {
		padding: 40px 0;
		@include media-breakpoint-up(md) {
			padding: 60px 0;
		}

		h3 {
			@include media-breakpoint-down(md) {
				font-size: $font-size-base * 0.9;
			}
			@include media-breakpoint-down(lg) {
				@include media-breakpoint-up(md) {
					font-size: $font-size-base * 1.3;
				}
			}
		}

		p {
			@include media-breakpoint-down(md) {
				font-size: $font-size-base * 0.7;
			}
			@include media-breakpoint-down(lg) {
				@include media-breakpoint-up(md) {
					font-size: $font-size-base * 0.9;
				}
			}
		}

		a {
			text-decoration: none;
			color: $body-color;
			display: block;
			width: 100%;
		}
	}

	.services-previous-btn {
		border-right: 1px solid $body-bg;
	}
	.services-next-btn {
		border-left: 1px solid $body-bg;
		text-align: end;
	}
}

@-webkit-keyframes passingLeft {
	0% {
		transform: translateX(50%);
		opacity: 0;
	}
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}

@keyframes passingLeft {
	0% {
		transform: translateX(50%);
		opacity: 0;
	}
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}

@-webkit-keyframes passingRight {
	0% {
		transform: translateX(-50%);
		opacity: 0;
	}
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}

@keyframes passingRight {
	0% {
		transform: translateX(-50%);
		opacity: 0;
	}
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}

.fa-passing-left {
	-webkit-animation: passingLeft 1s linear infinite;
	animation: passingLeft 1s linear infinite;
}
.fa-passing-right {
	-webkit-animation: passingRight 1s linear infinite;
	animation: passingRight 1s linear infinite;
}
