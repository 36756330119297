.section-solution {
	margin-top: 120px;

	h1 {
		@include media-breakpoint-down(md) {
			text-align: center;
		}
	}
	p {
		@include media-breakpoint-down(md) {
			text-align: center;
		}

		@include media-breakpoint-up(lg) {
			font-size: $font-size-base * 1.5;
		}
	}
}

.case-study-logo {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: #fff;
}

.solution-arrow {
	height: 120px;
	margin-top: 20px;
	text-align: center;

	@include media-breakpoint-up(md) {
		height: 200px;
	}

	img {
		height: 100%;
	}

	@include media-breakpoint-up(md) {
		&.dx {
			text-align: left;
			img {
				margin-left: 20%;
			}
		}
		&.sx {
			text-align: right;
			img {
				margin-right: 20%;
			}
		}
	}
}

.solutions-image {
	&:before {
		content: '';
		padding-top: 56.25%;
		display: block;
	}
}

#solution-image-composer {
	background-image: url('../../img/solutions/composer-future.jpg');
}
#solution-image-cloud {
	background-image: url('../../img/solutions/cloud-future.jpg');
}

/*CASE STUDY*/
#case-blackfin {
	background-image: url('../../img/solutions/case/blackfin.png');
}
#case-lightbird {
	background-image: url('../../img/solutions/case/lightbird.jpg');
}
#case-gibam {
	background-image: url('../../img/solutions/case/gibam.jpg');
}
#case-graniti {
	background-image: url('../../img/solutions/case/graniti.jpg');
}
