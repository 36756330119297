.duo-primary {
	--fa-primary-color: #28e6d4;
	--fa-primary-opacity: 1;
	--fa-secondary-color: #ffffff;
	--fa-secondary-opacity: 1;
}

.duo-primary-swap {
	--fa-primary-color: #ffffff;
	--fa-primary-opacity: 1;
	--fa-secondary-color: #28e6d4;
	--fa-secondary-opacity: 1;
}

.duo-primary-list {
	--fa-primary-color: #ffffff;
	--fa-primary-opacity: 1;
	--fa-secondary-color: #343a56;
	--fa-secondary-opacity: 1;
}

.fa-beat {
	--fa-beat-scale: 1.1;
}
